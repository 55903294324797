import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/LayoutMDX.jsx";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Address from "../components/Address";
import Telephone from "../components/Telephone";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        domain
        vatId
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
    <h1>{`Impressum`}</h1>
    <h2>{`Angaben gemäß § 5 TMG`}</h2>
    <Address showCompanyType mdxType="Address" />
    <h2>{`Kontakt`}</h2>
    <Telephone mdxType="Telephone" />
    <h2>{`Umsatzsteuer`}</h2>
    <p>{`Gemäß § 19 UStG wird keine Umsatzsteuer berechnet.`}</p>
    <p>USt-IdNr: {props.data.site.siteMetadata.vatId}</p>
    <h2>{`Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV`}</h2>
    <Address showCompanyType mdxType="Address" />
    <h2>{`E-Mail-Kontakt nach § 5 Nr. 2 TMG`}</h2>
    <p>
  E-Mail:{" "}
  <a href={"mailto:impressum@" + props.data.site.siteMetadata.domain}>
    {"impressum@" + props.data.site.siteMetadata.domain}
  </a>
    </p>
    <h2>{`Streitschlichtung`}</h2>
    <p>{`Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: `}<a parentName="p" {...{
        "href": "https://ec.europa.eu/consumers/odr"
      }}>{`https://ec.europa.eu/consumers/odr`}</a>{` Unsere E-MailAdresse finden Sie oben im Impressum.`}</p>
    <p>{`Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`}</p>
    <h2>{`Adressverarbeitung`}</h2>
    <p>{`Alle die auf dieser Webseite angegebenen Kontaktinformationen von Personen, inklusive etwaiger Fotos dienen ausdrücklich nur zu Informationszwecken bzw. zur Kontaktaufnahme. Sie dürfen insbesondere nicht für die Zusendung von Werbung, Spam und ähnliches genutzt werden. Einer werblichen Nutzung dieser Daten wird deshalb hiermit widersprochen. Sollten diese Informationen dennoch zu den vorstehend genannten Zwecken genutzt werden, behalten wir uns etwaige rechtliche Schritte vor.`}</p>
    <h2>{`Abmahnungsrechtlicher Hinweis`}</h2>
    <p>{`Eine Verletzung von Schutzrechten Dritter oder eine Situation, die eine Aufforderung per anwaltlicher Abmahnung motivieren könnte, entspricht nicht dem mutmaßlichen Willen der Betreiber dieser Webseiten. In jedem Fall sichern wir die sofortige Behebung der Verletzung zu. Kosten einer Abmahnung oder einer anwaltlichen Beratung können wir daher als offenkundlich mißbräuchlich nicht übernehmen.`}</p>
    <h2>{`Haftung für Inhalte`}</h2>
    <p>{`Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.`}</p>
    <p>{`Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.`}</p>
    <h2>{`Haftung für Links`}</h2>
    <p>{`Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.`}</p>
    <p>{`Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.`}</p>
    <h2>{`Urheberrecht`}</h2>
    <p>{`Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.`}</p>
    <p>{`Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.`}</p>
    <p>{`Quelle: `}<a parentName="p" {...{
        "href": "https://www.e-recht24.de/"
      }}>{`e-recht24.de`}</a></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      